<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}} : {{ career_field }}
            </h4>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmCareerPbaFormData && Object.keys(vmCareerPbaFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_field" class="w-100">
                    {{cvCarrImageLabel}}</label>
                  <div class="profile-img-edit border border-dark" style="height: 150px; width: 150px; border-radius: 50%; ">
                   <div v-if="vmProfilePic" >
                    <img alt="profile-pic " :src="vmProfilePic" style="height: 100%; width: 100%; object-fit: contain; overflow: hidden; border-radius: 50%; "/>
                    <button class="btn btn-primary ml-5 mt-1" @click="()=>imageEdit('Profile Pic')">
                      Save
                    </button>
                   </div>
                    <i v-else class="fas fa-orgersity d-flex justify-content-center align-items-center rounded-circle " style="font-size: 5rem; height:100%; "></i>
                    <label class="p-image">
                      <i class="ri-pencil-line upload-button" style="cursor: pointer" ></i>
                      <input class="file-upload" type="file" accept="image/png,image/jpeg,image/jpg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0;" @change="openImageDialog($event, 'Profile Pic')" />
                    </label>
                  </div>
                </div>

                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_field">
                    {{cvCarrFieldLabel}}</label>
                  <input
                    v-model="vmCareerPbaFormData.carr_field"
                    type="text"
                    class="form-control"
                    required
                    disabled/>
                </div>

                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_type">
                    {{cvCarrPbaTypeLabel}}</label>
                    <input
                      v-model="vmCareerPbaFormData.carr_pba_type"
                      type="text"
                      class="form-control"
                      required
                      disabled/>
                </div>

                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_meta">
                    Career Meta Title</label>
                  <input
                    v-model="vmCareerPbaFormData.carr_pba_meta.title"
                    type="text"
                    class="form-control"
                    required/>
                </div>

                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_meta">
                    Career Meta Description</label>
                    <textarea class="form-control" v-model="vmCareerPbaFormData.carr_pba_meta.description" rows="5" style="height:100px !important"></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_meta">
                    Career Meta Image Link</label>
                  <input
                    v-model="vmCareerPbaFormData.carr_pba_meta.image"
                    type="text"
                    class="form-control"
                    placeholder="If you uploaded image then this is optional"
                    required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_meta">
                    Career Click Link</label>
                  <input
                    v-model="vmCareerPbaFormData.carr_pba_meta.url"
                    type="text"
                    class="form-control"
                    required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_meta">
                    Career Meta Author</label>
                  <input
                    v-model="vmCareerPbaFormData.carr_pba_meta.author"
                    type="text"
                    class="form-control"
                    required/>
                </div>
              </div>
            <div class="form-group row spinner_add">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="careerPbaEdit()">
                  {{cvSubmitBtn}}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { CareerPbas } from "../../../FackApi/api/careerPba"
import ApiResponse from "../../../Utils/apiResponse.js"
import CropImage from "../../../components/cropImage.vue"
import { Images } from "../../../FackApi/api/image"

export default {
  name: "CareerPbaEdit",
  components: {
    CropImage
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propCareerPbaObj: {
      type: Object,
      default: function () {
        return {
          "carr_field": "",
          "carr_pba_type": "",
          "carr_pba_meta": "",
          "carr_pba_meta_title": "",
          "carr_pba_meta_image_url": "",
          "carr_pba_meta_url": "",
          "carr_pba_meta_description": "",
          "carr_pba_meta_author": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit Career Profile Building Activity",
      cvCardSubHeader: "Career Profile Building Activity ",
      cvCarrImageLabel: "carrer pba Image",
      cvSubmitBtn: "Edit",
      cvCarrFieldLabel: "Career Field",
      cvCarrPbaTypeLabel: "Career Activity Tab Name",
      cvCarrPbaMetaLabel: "Career Meta Data",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Career Profile Building Activity",
      vmCareerProPicImage: Object.assign({}, this.initImageData()),
      propCropImageDialogOpen: false,
      propCropDialogSize: "md",
      propStencilSize: null,
      fileSrc: "",
      carname: "",
      vmProfilePic: "",
      module_id: "",
      career_field: "",
      vmCareerPbaFormData: {}
    }
  },
  beforeMount () {
    this.module_id = this.$route.params.carr_pba_id
  },
  mounted () {
    this.careerPbaView()
    this.imageGetProfileImages()
  },
  methods: {
    /**
     * initImageData
     */
    initImageData () {
      return {
        module_id: this.module_id,
        module_name: "CARRPBA",
        image: [],
        image_type: {}
      }
    },

    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
    * onChangeProfileImage
    */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      if (imageType === "Profile Pic") {
        this.vmCareerProPicImage = Object.assign({}, this.initImageData())
        this.vmCareerProPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmCareerProPicImage.image_type[orgImg.name] = 2
      }
      else {
        this.vmOrganisationCoverPicImage = Object.assign({}, this.initImageData())
        this.vmOrganisationCoverPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmOrganisationCoverPicImage.image_type[orgImg.name] = 3
      }
    },
    testFunc () {
      console.log("TEST IMAGE CHANGES")
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerPbaFormData) {
          if (!this.vmCareerPbaFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        this.toastVariant = "danger"
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }
        this.toastVariant = ""
        if (imageType === "Profile Pic") {
          this.propStencilSize = {
            "width": 150,
            "height": 150
          }
          this.propCropDialogSize = "md"
        }
        else {
          this.propStencilSize = {
            "width": 970,
            "height": 240
          }
          this.propCropDialogSize = "xl"
        }
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * careerPbaView
     */
    async careerPbaView () {
      this.propOpenedInModal = false
      try {
        if (this.propOpenedInModal) {
          this.vmCareerPbaFormData = this.propCareerPbaObj
          this.vmCareerPbaFormData.carr_pba_meta = JSON.parse(this.vmCareerPbaFormData.carr_pba_meta)
        }
        else {
          let careerPbaId = this.$route.params.carr_pba_id
          let careerPbaViewResp = await CareerPbas.careerPbaView(this, careerPbaId)
          this.career_field = careerPbaViewResp.resp_data.data.carr_field
          if (careerPbaViewResp && careerPbaViewResp.resp_status) {
            careerPbaViewResp.resp_data.data.carr_pba_meta = JSON.parse(careerPbaViewResp.resp_data.data.carr_pba_meta)
            this.vmCareerPbaFormData = careerPbaViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at careerPbaView() and Exception:", err.message)
      }
    },
    /**
     * careerPbaEdit
     */
    async careerPbaEdit () {
      try {
        this.cvLoadingStatus = true
        let vmCareerPbaFormData = { ...this.vmCareerPbaFormData }
        vmCareerPbaFormData.carr_pba_meta = JSON.stringify(vmCareerPbaFormData.carr_pba_meta)
        let careerPbaAddResp = await CareerPbas.careerPbaEdit(this, vmCareerPbaFormData)
        await ApiResponse.responseMessageDisplay(this, careerPbaAddResp)
        if (careerPbaAddResp && !careerPbaAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCareerPbaEditModal", this.vmCareerPbaFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at careerPbaEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * imageGetProfileImages()
     */
    async imageGetProfileImages () {
      try {
        let carId = this.$route.params.carr_pba_id
        let imageProfileResp = await Images.imageListProfileImages(this, carId)
        if (imageProfileResp && !imageProfileResp.resp_status) {
          return false
        }
        else {
          this.profilePicObj = imageProfileResp.resp_data.profile_pic
          this.coverPicObj = imageProfileResp.resp_data.cover_pic
          this.vmProfilePic = imageProfileResp.resp_data.profile_pic ? imageProfileResp.resp_data.profile_pic.image_thumbnail : ""
          this.vmCoverPic = imageProfileResp.resp_data.cover_pic ? imageProfileResp.resp_data.cover_pic.image : ""
        }
      }
      catch (err) {
        console.error("Exception occurred at imageGetProfileImages() and Exception:", err.message)
      }
    },
    /**
     * imageEdit
     */
    async imageEdit (imageType) {
      if (imageType === "Profile Pic" && this.vmCareerProPicImage.image.length === 0) {
        return false
      }
      if (imageType === "Cover Pic" && this.vmOrganisationCoverPicImage.image.length === 0) {
        return false
      }
      try {
        let image
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = true
          image = this.vmCareerProPicImage
        }
        else {
          this.cvCoverPicLoading = true
          image = this.vmOrganisationCoverPicImage
        }
        const imageUpdateResp = await Images.imageProfileUpdate(this, image)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        let imageResp = imageUpdateResp.resp_data
        if (imageResp && imageResp.length > 0) {
          if (imageType === "Profile Pic") {
            this.profilePicObj = imageResp[0]
          }
          else {
            this.coverPicObj = imageResp[0]
          }
          await this.addOrgToModules()
        }
        this.vmCareerProPicImage = Object.assign({}, this.initImageData())
      }
      catch (err) {
        console.error("Exception occurred at imagEdit() and Exception:", err.message)
      }
      finally {
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = false
        }
        else {
          this.cvCoverPicLoading = false
        }
      }
    }
  }
}
</script>
